.experience-page {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: var(--text-primary);
  background-color: var(--primary-bg);
}

.experience-section {
  padding: 40px 20px;
  text-align: center;
}

.experience-section h1 {
  font-size: 2.8em;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--text-primary);
}

.experience-section h3 {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: var(--text-secondary);
}

.work-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.work-card {
  background-color: var(--secondary-bg);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
}

.work-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.2); 
}

.work-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.work-title {
  text-align: left;
}

.work-title h2 {
  font-size: 1.4em;
  font-weight: bold;
  color: var(--text-primary);
}

.work-title h4 {
  font-size: 1em;
  font-weight: normal;
  color: var(--text-secondary);
}

.work-meta {
  text-align: right;
  display: flex;
  flex-direction: column;
}

.work-meta span {
  display: block;
  font-size: 0.9em;
  color: var(--text-secondary);
}

.work-details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.work-details.expanded {
  padding-top: 10px;
  max-height: 350px; 
}

.tech-stack {
  font-size: 1em;
  font-weight: bold;
  color: var(--accent);
}

.details-section {
  margin-top: 20px;
  background-color: #ecf0f1;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.details-section h4 {
  font-size: 1.1em;
  font-weight: bold;
  color: var(--text-primary);
}

.details-section ul {
  list-style-type: disc;
  margin-left: 20px;
  font-size: 1em;
}

.details-section li {
  margin-bottom: 10px; 
}

.details-list li::marker {
  color: #2980b9; 
}

#projects-section {
  padding: 40px 20px;
  text-align: center;
}

#projects-section h1 {
  font-size: 2.8em;
  margin-bottom: 10px;
}

#projects-section h3 {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: var(--text-secondary);
}

.project-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.project-card {
  background-color: var(--secondary-bg);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.project-card:hover {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.2); 
}

.project-card h2 {
  font-size: 1.4em;
  margin-top: 10px;
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.projects-section h1 {
  font-size: 2.8em;
  font-weight: bold;
  text-align: center;
  color: var(--text-primary);
  margin-bottom: 10px;
}

.projects-section h3 {
  font-size: 1.2em;
  font-weight: normal; 
  text-align: center;
  color: var(--text-secondary);
  margin-bottom: 30px;
}

.popup-container {
  background-color: var(--secondary-bg);
  padding: 30px;
  border-radius: 10px;
  max-width: 70%;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); 
  position: relative;
  animation: popupFadeIn 0.3s ease-out;
}
.popup-container .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 2em;
  color: var(--text-secondary);
  cursor: pointer;
}

.popup-image {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 8px;
  margin-bottom: 20px;
}

.popup-container h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: var(--text-primary);
}

.popup-container p {
  font-size: 1.1em;
  line-height: 1.5;
  color: var(--text-secondary);
}

@media (min-width: 1280px) {
  .work-container {
    max-width: 900px;
  }
  .project-container {
    max-width: 1200px;
  }
}
