body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-bg: #F5F5F0;
  --secondary-bg: #EAE7DC;
  --accent: #001F3F;
  --text-primary: #0A0A0A;
  --text-secondary: #4A4A4A;
  --border-color: #778899;
}



body {
  background-color: var(--primary-bg);
  color: var(--text-primary);
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}
