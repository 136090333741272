html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--primary-bg);
  font-family: Arial, sans-serif;
  color: var(--text-primary);
}

.home-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--primary-bg);
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.home-container {
  text-align: center;
  opacity: 0;
  animation: fadeIn 1.5s 0.5s forwards;
}

.home-text h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: var(--text-primary);
}

.home-buttons {
  margin-top: 20px;
  opacity: 0;
  animation: fadeIn 1.5s 1.5s forwards;
}

.home-button {
  display: inline-block;
  padding: 12px 25px;
  margin: 10px;
  text-decoration: none;
  background-color: var(--accent);
  color: var(--primary-bg);
  border-radius: 5px;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: var(--border-color);
}

.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-item {
  display: flex;
  align-items: center;
}

.link-item p {
  margin: 0;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  padding-left: 10px;
}


.link-item a {
  color: var(--text-primary); 
  text-decoration: none;
  display: flex; 
  align-items: center;
  transition: color 0.3s ease;
}

.link-item a:hover {
  color: var(--accent); 
}

.link-item p {
  margin: 0;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .home-section {
    flex-direction: column;
    padding: 20px;
    height: auto;
  }

  .home-text h1 {
    font-size: 2.5em;
    margin-bottom: 15px;
  }

  .home-buttons {
    margin-top: 15px;
  }

  .home-button {
    padding: 10px 20px;
    font-size: 1em;
    margin: 8px;
  }

  .contact-info {
    flex-direction: column;
    gap: 20px;
  }

  .link-item {
    flex-direction: row; 
  }

  .link-item p {
    padding-left: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .home-text h1 {
    font-size: 2em;
  }

  .home-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .contact-info {
    gap: 15px;
  }

  .link-item p {
    font-size: 0.9em;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
